<template>
  <div>
    <div class="flex items-center pt-8 pb-4">
      <span class="mr-auto text-lg font-medium truncate ml-3 font-bold">权限管理</span>
      <div class="flex items-center">
        <button class="button mr-3 bg-theme-1 text-white" @click="init(true)">
          <i class="el-icon-refresh-left mr-1"></i>
          <span>刷新</span>
        </button>
      </div>
    </div>

    <div class="report-box zoom-in box p-4 intro-y" style="cursor: auto">
      <el-form
        ref="ruleForm"
        :inline="true"
        :model="formData"
        class="flex items-center"
        style="width: 100%"
        size="small"
      >
        <div class="flex pt-4 pb-4">
          <el-form-item label="权限分组" prop="grouping">
            <el-select v-model="formData.grouping" placeholder="全部">
              <el-option
                v-for="item in state_set"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="日期" prop="pickerArr">
            <el-date-picker
              v-model="formData.pickerArr"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width: 200px"
            >
            </el-date-picker>
          </el-form-item>

          <!-- </div> -->
          <!-- <div class="ml-auto"> -->
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="onSearch"
              >搜索</el-button
            >
            <el-button icon="el-icon-refresh-right" @click="resetForm('ruleForm')"
              >重置</el-button
            >
          </el-form-item>
          <!-- </div> -->
        </div>
      </el-form>

      <el-table
        ref="multipleTable"
        v-loading="tableLoading"
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column prop="merchant_name" label="权限分组名称"> </el-table-column>
        <el-table-column prop="merchant_phone" label="人数"> </el-table-column>
        <el-table-column prop="merchant_phone" label="备注"> </el-table-column>
        <el-table-column prop="created_at" label="创建日期"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span class="mr-6 text-theme-1" @click="handleClick(scope.row)">编辑</span>

            <span class="text-theme-6" @click="handleDetele(scope.row)">删除</span>
          </template>
        </el-table-column>
      </el-table>

      <div class="flex mt-10">
        <el-pagination
          :current-page.sync="paginate.p"
          :page-size="paginate.limit"
          layout="total, prev, pager, next"
          :total="paginate.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <CashBox ref="CashBox" :app-list="app_list"></CashBox>
  </div>
</template>

<script>
import request from "@/utils/request";
import CashBox from "./components/Cashbox.vue";

export default {
  components: {
    CashBox,
  },
  data() {
    return {
      tableData: [],
      tableLoading: true,
    app_list: [],
      state_set: [], // 状态列表
      paginate: {},
      formData: {
        pickerArr: [],
        grouping: null,
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    handleClick(data) {
      cash("#newBusinesses").modal("show");
      this.$refs["CashBox"].formData = data;
    },

    // 授权模式
    handleAuthorization() {},

    init(refresh = false) {
      this.tableLoading = true;
      request({
        url: "/merchant/merchant/getMerchantList",
        method: "GET",
        data: this.formData,
      }).then((res) => {
        this.tableLoading = false;
        this.tableData = res.data.list;
        this.app_list = res.data.app_list;
        this.state_set = res.data.state_set;
        this.paginate = res.data.paginate;

        if (refresh) {
          this.$message.success("刷新成功");
        }
      });
    },
    // 搜索
    onSearch() {
      this.init();
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.init(true);
    },
    // 单个删除
    handleDetele(data) {
      this.$confirm("此操作将永久删除该商户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          request({
            url: "/merchant/merchant/delMerchant",
            method: "POST",
            data: {
              ids: [data.id],
            },
          }).then((res) => {
            if (res.code === 0) {
              this.$message.success("删除成功");
              this.init();
            } else {
              console.error("未知错误：" + res.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    handleSizeChange() {},
    handleCurrentChange() {},
  },
};
</script>

<style lang="scss" scoped></style>
